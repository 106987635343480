import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import { UserService } from '../../../services/UserService';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../../store/userSlice';
import { toast } from 'react-toastify';

const BOARD_SIZE = 20;
const INITIAL_SNAKE = [{ x: 8, y: 8 }];
const INITIAL_DIRECTION = { x: 0, y: 1 };
const INITIAL_FOOD = { x: 5, y: 5 };

function Index({ isGameStarted, setIsGameStarted, isGameModalOpen, setIsGameModalOpen, showConfetti, setShowConfetti }) {
  const [snake, setSnake] = useState(INITIAL_SNAKE);
  const [food, setFood] = useState(INITIAL_FOOD);
  const [direction, setDirection] = useState(INITIAL_DIRECTION);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const claim = async (score) => {
        try {
          const res = await UserService.claimGamePoints(userData?.id, score);
          if (res.data.success) {
            dispatch(setUser(res.data.data));
            setIsGameModalOpen(false);
            toast.success('Congratulations! You have successfully claimed your reward');
            setIsGameStarted(false);
            setShowConfetti(true);
          }
        } catch (err) {
          const resData = err?.response?.data;
          toast.error(resData?.message || 'Something went wrong');
          setIsGameModalOpen(false);
          setIsGameStarted(false);
        }
  };

  const moveSnake = useCallback(() => {
    if (gameOver) return;

    const newSnake = [...snake];
    const head = { 
      x: newSnake[0].x + direction.x, 
      y: newSnake[0].y + direction.y 
    };

    // Check if snake hits the wall or itself
    if (
      head.x < 0 || head.y < 0 || 
      head.x >= BOARD_SIZE || head.y >= BOARD_SIZE ||
      newSnake.some(segment => segment.x === head.x && segment.y === head.y)
    ) {
        setGameOver(true);
        toast.error('Game Over! You hit the wall or yourself');
        if (score > 0) {
            claim(score);
        } else {
            setIsGameModalOpen(false);
            setIsGameStarted(false);
        }
      return;
    }

    newSnake.unshift(head);

    // Check if snake eats the food
    if (head.x === food.x && head.y === food.y) {
      setScore(score + 1);
      setFood({
        x: Math.floor(Math.random() * BOARD_SIZE),
        y: Math.floor(Math.random() * BOARD_SIZE)
      });
    } else {
      newSnake.pop();
    }

    setSnake(newSnake);
  }, [snake, direction, food, score, gameOver]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowUp':
          if (direction.y === 0) setDirection({ x: 0, y: -1 });
          break;
        case 'ArrowDown':
          if (direction.y === 0) setDirection({ x: 0, y: 1 });
          break;
        case 'ArrowLeft':
          if (direction.x === 0) setDirection({ x: -1, y: 0 });
          break;
        case 'ArrowRight':
          if (direction.x === 0) setDirection({ x: 1, y: 0 });
          break;
        default:
          break;
      }
    };

    const handleTouchStart = (e) => {
      const touch = e.touches[0];
      setTouchStart({ x: touch.clientX, y: touch.clientY });
    };

    const handleTouchMove = (e) => {
      if (!touchStart) return;

      const touch = e.touches[0];
      const deltaX = touch.clientX - touchStart.x;
      const deltaY = touch.clientY - touchStart.y;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        // Swipe left or right
        if (deltaX > 0 && direction.x === 0) {
          setDirection({ x: 1, y: 0 }); // Swipe right
        } else if (deltaX < 0 && direction.x === 0) {
          setDirection({ x: -1, y: 0 }); // Swipe left
        }
      } else {
        // Swipe up or down
        if (deltaY > 0 && direction.y === 0) {
          setDirection({ x: 0, y: 1 }); // Swipe down
        } else if (deltaY < 0 && direction.y === 0) {
          setDirection({ x: 0, y: -1 }); // Swipe up
        }
      }

      setTouchStart(null); // Reset touch start position after swipe
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [direction, touchStart]);

  useEffect(() => {
    const interval = setInterval(moveSnake, 200);
    return () => clearInterval(interval);
  }, [moveSnake]);

  return (
    <div className="game-board">
      {Array.from({ length: BOARD_SIZE }).map((_, rowIndex) => (
        <div key={rowIndex} className="row">
          {Array.from({ length: BOARD_SIZE }).map((_, colIndex) => {
            const isSnake = snake.some(segment => segment.x === colIndex && segment.y === rowIndex);
            const isFood = food.x === colIndex && food.y === rowIndex;
            return (
              <div
                key={colIndex}
                className={`cell ${isSnake ? 'snake' : ''} ${isFood ? 'food' : ''}`}
              />
            );
          })}
        </div>
      ))}
      <div className="score bg-gradient-1 rounded-[30px] text-[14px] p-2">DNB: {score}</div>
    </div>
  );
}

export default Index;