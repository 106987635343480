import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSwipeable } from 'react-swipeable';
import CourseCard from '../components/CourseCard';
import BottomSheet from '../components/BottomSheet';
import TaskCard from '../components/TaskCard';
import Confetti from 'react-confetti';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../store/userSlice';
import { useNavigate } from 'react-router-dom';
import { UserService } from '../services/UserService';
import Button from '../components/Button';
import { IoClose, IoSearch } from "react-icons/io5";
import { toast } from 'react-toastify';
import { IoVideocam, IoWallet, IoGameController, IoList, IoPersonAdd, IoPeople } from "react-icons/io5";
import { FaRegUser, FaGraduationCap, FaChartLine } from 'react-icons/fa';
import useLoading from '../hooks/useLoading';
import AdCard from '../components/AdCard';

const telApp = window.Telegram.WebApp;
const Task = () => {
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [activeTab, setActiveTab] = useState('Social');
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const user = useSelector((state) => state.user.user);
  const profileUrl = useSelector((state) => state.user.profileUrl);

  const [tasks, setTasks] = useState([]);
  const [prevPointCount, setPrevPointCount] = useState(user?.pointCount || 0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [loadingTaskId, setLoadingTaskId] = useState(null);
  const [taskStarted, setTaskStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTaskClaimDialogOpen, setIsTaskClaimDialogOpen] = useState(false);
  const [claimCode, setClaimCode] = useState('');
  const { startLoading, completeLoading, LoadingComponent } = useLoading();
  const taskToDisplay = useMemo(() => tasks?.filter(task => task.action === activeTab.toLowerCase()), [tasks, activeTab]);

  const sampleDailyTasks = [
    { id: 1, name: 'Follow ELO CEO on instagram', point: 100, status: 'finished', action: 'social', link: 'https://t.me/eloofficial' },
    { id: 2, name: 'Follow ELO CEO on facebook', point: 200, status: 'active', action: 'social', link: 'https://t.me/eloofficial' },
    { id: 3, name: 'Task 3', point: 300, status: 'pending', action: 'social', link: 'https://t.me/eloofficial' },
    { id: 4, name: 'Task 4', point: 400, status: 'pending', action: 'video', link: 'https://t.me/eloofficial' },
    { id: 5, name: 'Task 5', point: 500, status: 'pending', action: 'video', link: 'https://t.me/eloofficial' },
    { id: 6, name: 'Task 6', point: 600, status: 'pending', action: 'video', link: 'https://t.me/eloofficial' },
    { id: 7, name: 'Task 7', point: 700, status: 'finished', action: 'academy', link: 'https://t.me/eloofficial' },
    { id: 8, name: 'Task 8', point: 800, status: 'pending', action: 'academy', link: 'https://t.me/eloofficial', code: 'SATOSHI' },
    { id: 9, name: 'Task 9', point: 900, status: 'pending', action: 'academy', link: 'https://t.me/eloofficial', code: 'SATOSHI' },
    { id: 10, name: 'Task 10', point: 1000, status: 'finished', action: 'social', link: 'https://t.me/eloofficial', code: 'SATOSHI' },
  ];

  const actionIcons = {
    wallet: <IoWallet className="text-[60px] text-white" />,
    video: <IoVideocam className="text-[60px] text-white" />,
    social: <IoPeople className="text-[60px] text-white" />,
    academy: <FaGraduationCap className="text-[60px] text-white" />,
    game: <IoGameController className="text-[60px] text-white" />,
    task: <IoList className="text-[60px] text-white" />,
    referral: <IoPersonAdd className="text-[60px] text-white" />,
  };
  const randomIndex = Math.floor(Math.random() * taskToDisplay.length);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "development") {
      setTasks(sampleDailyTasks);
    }
  }, []);

  const getTasks = useCallback(async () => {
    startLoading()
    try {
      const res = await UserService.getTask(userData?.id);
      const resData = res?.data;
      if (resData.success) {
        completeLoading()
        setTasks(resData?.data?.tasks);
      }
    } catch (err) {
      completeLoading()
      const resData = err?.response?.data;
      // toast.error(resData?.message || 'Something went wrong');
    }
  }, [userData?.id]);

  const handleTakeTask = useCallback(async (id, name, point, status, link) => {
    // setLoadingTaskId(id);
    if (link) {
      if (link.startsWith('https://t.me')) {
        telApp.openTelegramLink(link);
      } else {
        telApp.openLink(link, [{ try_instant_view: true }]);
      }
    }
    setTaskStarted(true);
  }, [userData?.id, setUser, tasks]);

  const handleClaimTask = useCallback(async (id, name, point, status, link) => {
    setLoadingTaskId(id);

    try {
      const res = await UserService.takeTask(userData?.id, id);
      const resData = res?.data;
      if (resData.success) {
        dispatch(setUser(resData.data.user));
        setTasks(prevTasks =>
          prevTasks.map(task => (task.id === id ? { ...task, status: 'finished' } : task))
        );
        toast.success(`Task "${name}" claimed successfully!`);
        setShowConfetti(true)
        setSelectedTask(null)
        setIsTaskModalOpen(false)
      } else {
        toast.error(resData.message);
      }
    } catch (err) {
      setLoadingTaskId(null);
      const resData = err?.response?.data;
      toast.error(resData?.message || 'Something went wrong');
    } finally {
      setLoadingTaskId(null);
    }
  }, [userData?.id, setUser, tasks]);

  useEffect(() => {
    if (userData?.id) getTasks();
    if (user?.pointCount !== prevPointCount) {
      setPrevPointCount(user?.pointCount);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    }
  }, [userData, user?.pointCount, prevPointCount, getTasks]);

  const tabItems = useMemo(() => [
    { label: 'Social', icon: <IoPeople />, action: 'Social' },
    { label: 'Video', icon: <IoVideocam />, action: 'Video' },
    { label: 'Academy', icon: <FaGraduationCap />, action: 'Academy' },
    { label: 'Onchain', icon: <FaChartLine />, action: 'Onchain' }
  ], []);

  const handleSwipeLeft = () => {
    const currentIndex = tabItems.findIndex(tab => tab.action === activeTab);
    const nextIndex = (currentIndex + 1) % tabItems.length;
    setActiveTab(tabItems[nextIndex].action);
  };

  const handleSwipeRight = () => {
    const currentIndex = tabItems.findIndex(tab => tab.action === activeTab);
    const prevIndex = (currentIndex - 1 + tabItems.length) % tabItems.length;
    setActiveTab(tabItems[prevIndex].action);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  });

  return (
    <div className="flex flex-col min-h-screen bg-[#16171A] text-white p-2" {...swipeHandlers}>
      {LoadingComponent}
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={200} recycle={false} />}
      <div className="flex items-center justify-between absolute top-6 left-6 right-6">
        <div>
          <h1 className="text-3xl font-bold">Earning</h1>
          <p className="mt-1 text-[13px] text-lightGray">
            Earn DNB points by completing tasks
          </p>
        </div>
      </div>

      <div className="flex justify-start mt-20 mb-4 ml-[20px] mr-[20px] overflow-x-auto whitespace-nowrap">
        <div className="flex space-x-2">
          {tabItems.map((tab, index) => (
            <button
              key={index}
              onClick={() => setActiveTab(tab.action)}
              className={`flex items-center px-4 py-2 rounded-t-md ${activeTab === tab.action ? 'bg-[#232336] text-white' : 'bg-[#1b1b22] text-gray-400'}`}
            >
              {tab.icon} {" "} {tab.label}
            </button>
          ))}
        </div>
      </div>

      {
        taskToDisplay && taskToDisplay.length > 0 && (
          <div className="overflow-y-auto max-h-[400px] mt-6 mb-10">
            {taskToDisplay.map((task, index) => (
              <React.Fragment key={index}>
                <TaskCard
                  task={task}
                  loadingTaskId={loadingTaskId}
                  isTaskModalOpen={isTaskModalOpen}
                  setIsTaskModalOpen={setIsTaskModalOpen}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                />
                {index === randomIndex && (
                  <AdCard userData={userData} user={user} profileUrl={profileUrl} />
                )}
              </React.Fragment>
            ))}
          </div>
        )
      }
      <div className="overflow-y-auto max-h-[300px] mt-6 mb-10">
        {
          tasks?.length === 0 && (
            <div className="flex items-center justify-center h-full">
              <h1 className="text-white text-0xl">No tasks available</h1>
            </div>
          )
        }
      </div>

      <BottomSheet
        className="h-[50%]"
        isOpen={isTaskModalOpen}
        onClose={() => setIsTaskModalOpen(false)}
      >
        <div className="relative">
          <button
            className="absolute top-2 right-2 text-white"
            onClick={() => {
              setIsTaskModalOpen(false);
              setSelectedTask(null);
            }}
          >
            <IoClose size={20} />
          </button>
          <div className="flex flex-col justify-center">
            <div className="flex flex-col items-center justify-center h-full">
              <div className="mb-4">
                {actionIcons[selectedTask?.action]}
              </div>
              <div className="text-white text-xl mb-0">
                {selectedTask?.name}
              </div>
              <div className="text-white text-[12px] mb-4">
                +{selectedTask?.point} DNB
              </div>

              <Button
                // loading={loadingTaskId === selectedTask?.id}
                disabled={taskStarted}
                className="bg-gradient-1 text-white text-[13px] px-4 py-2 items-center w-full h-[35px] rounded-[100px] hover:bg-gradient-2 flex justify-center mt-2"
                onClick={() => handleTakeTask(selectedTask?.id, selectedTask?.name, selectedTask?.point, selectedTask?.status, selectedTask?.link)}
              >
                {selectedTask?.status === 'finished' ? 'Done' : 'Start Task'}
              </Button>

              <Button
                loading={loadingTaskId === selectedTask?.id}
                color={selectedTask?.status === 'finished' ? 'success' : 'primary'}
                className="bg-gradient-3 text-white text-[13px] px-4 py-2 items-center w-full h-[35px] rounded-[100px] hover:bg-gradient-2 flex justify-center mt-2"
                onClick={() => {
                  if (!taskStarted) {
                    toast.error('Task not started yet');
                    return;
                  }

                  if (selectedTask?.action === 'academy') {
                    setIsTaskClaimDialogOpen(true);
                  } else {
                    handleClaimTask(selectedTask?.id, selectedTask?.name, selectedTask?.point, selectedTask?.status, selectedTask?.link)
                  }
                }}
                disabled={selectedTask?.status === 'finished'}
              >
                {selectedTask?.status === 'finished' ? 'Check Task' : 'Check Task'}
              </Button>


            </div>
          </div>

        </div>
      </BottomSheet>

      {isTaskClaimDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#232336] p-6 rounded-md w-[90%] max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl text-white font-bold">Reward Code</h2>
              <button onClick={() => setIsTaskClaimDialogOpen(false)} className="text-white">
                <IoClose size={24} />
              </button>
            </div>
            <p className="text-white text-[13px] mb-4">Enter the code from the video to claim your reward</p>
            <input
              type="text"
              className="w-full p-2 rounded-md bg-[#1b1b22] text-white focus:outline-none"
              placeholder="Code"
              value={claimCode}
              onChange={(e) => {
                setClaimCode(e.target.value.toUpperCase());
              }}
            />
            <div className="flex justify-end mt-4">
              <Button
                className="bg-gradient-3 text-white text-[13px] px-4 py-2 items-center w-full h-[35px] rounded-[100px] hover:bg-gradient-2 flex justify-center mt-2"
                onClick={() => {
                  if (selectedTask?.code === claimCode) {
                    setIsTaskClaimDialogOpen(false);
                    handleClaimTask(selectedTask?.id, selectedTask?.name, selectedTask?.point, selectedTask?.status, selectedTask?.link)
                  } else {
                    toast.error('Invalid code');
                  }
                }}
              >
                Claim
              </Button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Task;