import React from 'react';
import { IoVideocam, IoWallet, IoGameController, IoList, IoPersonAdd , IoPeople } from "react-icons/io5";
import Button from './Button';
import { FaRegUser, FaGraduationCap } from 'react-icons/fa';

const TaskCard = ({ task, loadingTaskId, setIsTaskModalOpen, isTaskModalOpen, setSelectedTask, selectedTask }) => {
  const actionIcons = {
    wallet: <IoWallet className="text-[20px] text-white" />,
    video: <IoVideocam className="text-[20px] text-white" />,
    social: <IoPeople className="text-[20px] text-white" />,
    academy: <FaGraduationCap className="text-[20px] text-white" />,
    game: <IoGameController className="text-[20px] text-white" />,
    task: <IoList className="text-[20px] text-white" />,
    referral: <IoPersonAdd className="text-[20px] text-white" />,
    onchain: <IoWallet className="text-[20px] text-white" />,
  };
  return (
    <div className="bg-[#232336] text-white p-4 rounded-2xl w-[90%] mx-auto flex items-center justify-between mb-4">
      <div className="flex items-center">
      {actionIcons[task?.action]}
        <div className="ml-4 text-left">
          <span className="text-[12px] font-medium">{task?.name}</span>
          <span className="text-sm mt-1 text-[12px] block text-primary-light">+ {task?.point} DNB</span>
        </div>
      </div>
      <Button
        loading={loadingTaskId === task?.id}
        color={task?.status === 'finished' ? 'success' : 'primary'}
        className={`bg-gradient-${task?.status === 'finished' ? '3' : '1'} text-white text-[13px] px-4 py-2 items-center w-[80px] h-[35px] rounded-[100px] hover:bg-gradient-2 flex justify-center`}
        onClick={() => {
          setSelectedTask(task);
          setIsTaskModalOpen(!isTaskModalOpen);
        }}
        disabled={task?.status === 'finished' ? true : false}
      >
        {task?.status === 'finished' ? 'Done' : 'Start'}
      </Button>
    </div>
  );
};

export default TaskCard;
