import React, { useState, useEffect } from 'react';
import GridLayout from 'react-grid-layout';
import { toast } from 'react-toastify';
import { UserService } from '../../services/UserService';
import { useSelector, useDispatch } from 'react-redux';
import { setUserData, setUser, setProfileUrl } from '../../store/userSlice';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './DailyCipher.css';

const generateRandomLayout = (letters) => {
  return letters.map((_, i) => ({
    i: i.toString(),
    x: Math.floor(Math.random() * 8), // Random x position
    y: Math.floor(Math.random() * 4), // Random y position
    w: 2,
    h: 1,
  }));
};

const WordArrangementGame = ({ dailyCipher, isGameModalOpen, setIsGameModalOpen, showConfetti, setShowConfetti }) => {
  const [letters, setLetters] = useState([]); // Initialize as an empty array
  const [layout, setLayout] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const user = useSelector((state) => state.user.user);
  const profileUrl = useSelector((state) => state.user.profileUrl);
  const [initialWord, setInitialWord] = useState('');

  useEffect(() => {
    if (dailyCipher) {
      setInitialWord(dailyCipher);
      setLetters(dailyCipher.split(''));
    }
  }, [dailyCipher]);

  useEffect(() => {
    if (letters.length > 0) {
      setLayout(generateRandomLayout(letters));
    }
  }, [letters]);

  const claim = async () => {
    try {
      const res = await UserService.claimDailyCipher(userData?.id);
      if (res.data.success) {
        dispatch(setUser(res.data.data));
        setIsGameModalOpen(false);
        toast.success('Congratulations! You have successfully claimed your reward');
        setShowConfetti(true);
      }
    } catch (err) {
      const resData = err?.response?.data;
      // toast.error(resData?.message || 'Something went wrong');
    }
  };

  const handleDragStop = (newLayout) => {
    setLayout(newLayout);
    const sortedLetters = newLayout
      .sort((a, b) => a.x - b.x)
      .map(item => letters[item.i]);

    if (sortedLetters.join('') === initialWord) {
      claim();
    }
  };

  const handleLetterChange = (index, newValue) => {
    const newLetters = [...letters];
    newLetters[index] = newValue;
    setLetters(newLetters);
  };

  return (
    <div>
      <h1 className="text-white mt-4 p-4 text-center">
        Drag and Drop the letters to arrange the daily cipher codes
      </h1>
      <GridLayout
        className="layout"
        layout={layout}
        cols={initialWord.length}
        rowHeight={50}
        width={180}
        onDragStop={handleDragStop}
        isResizable={false}
      >
        {letters?.map((letter, index) => (
          <div
            key={index}
            data-grid={layout.find((item) => item.i === index.toString())}
            className="grid-item"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              value={letter}
              onChange={(e) => handleLetterChange(index, e.target.value)}
              readOnly
              style={{
                width: "20px",
                height: "30px",
                textAlign: "center",
                border: "none",
                outline: "none",
              }}
            />
          </div>
        ))}
      </GridLayout>
    </div>
  );
};

export default WordArrangementGame;
