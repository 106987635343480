import React from 'react';
import { PiGameControllerBold } from "react-icons/pi";

const GameCard = ({ logo, name, points, isGameStarted, isGameModalOpen, selectedGame, setIsGameModalOpen, setIsGameStarted, setSelectedGame  }) => {
  return (
    <div
      onClick={() => {
        setIsGameModalOpen(true);
        setSelectedGame(name);
      }}
      className="bg-[#232336] text-white p-4 rounded-[12px] flex items-center justify-between mb-2">
      <div className="flex items-center">
        <PiGameControllerBold size={24}/>
        <div className="ml-4">
          <h2 className="text-[15px] font-medium">{name}</h2>
        </div>
      </div>
    </div>
  );
};

export default GameCard;
