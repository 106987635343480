import React, { useState, useEffect } from 'react';
import Button from './Button';
import { toast } from 'react-toastify';
import { UserService } from '../services/UserService';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/userSlice';

const formatTimeLeft = (timeLeftInMs) => {
  const hours = Math.floor(timeLeftInMs / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeftInMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeftInMs % (1000 * 60)) / 1000);
  return `${hours}h ${minutes}m ${seconds}s`;
};

const FarmingCard = ({ userData }) => {
  const [isFarming, setIsFarming] = useState(false);
  const [farmingStartTime, setFarmingStartTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [pointsEarned, setPointsEarned] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const farmingData = JSON.parse(localStorage.getItem('farmingData'));
    if (farmingData) {
      const { startTime, finished } = farmingData;
      if (finished) {
        setIsFinished(true);
        setPointsEarned(farmingData.pointsEarned);
        setProgress(100);
      } else if (startTime) {
        const timeElapsed = Date.now() - startTime;
        if (timeElapsed < 3 * 60 * 60 * 1000) {
          setIsFarming(true);
          setFarmingStartTime(startTime);
          setPointsEarned(Math.floor(timeElapsed / (1000 * 60 * 30)) * 50);
          setTimeLeft(3 * 60 * 60 * 1000 - timeElapsed);
          setProgress((timeElapsed / (3 * 60 * 60 * 1000)) * 100);
        } else {

          setIsFarming(false);
          setIsFinished(true);
          setPointsEarned(Math.floor(3 * 60 * 60 * 1000 / (1000 * 60 * 30)) * 50);
          setProgress(100); 
          localStorage.setItem('farmingData', JSON.stringify({ finished: true, pointsEarned: Math.floor(60 * 1000 / (1000 * 10)) * 10 }));
        }
      }
    }
  }, []);

  useEffect(() => {
    let timer;
    if (isFarming) {
      timer = setInterval(() => {
        const timeElapsed = Date.now() - farmingStartTime;
        const remainingTime = 3 * 60 * 60 * 1000 - timeElapsed;
        setTimeLeft(remainingTime);

        setPointsEarned(Math.floor(timeElapsed / (1000 * 60 * 30)) * 10);

        setProgress((timeElapsed / (3 * 60 * 60 * 1000)) * 100);

        if (remainingTime <= 0) {
          clearInterval(timer);
          setIsFarming(false);
          setIsFinished(true);
          setPointsEarned(Math.floor(3 * 60 * 60 * 1000 / (1000 * 60 * 30)) * 50);
          setProgress(100);
          localStorage.setItem('farmingData', JSON.stringify({ finished: true, pointsEarned: Math.floor(60 * 1000 / (1000 * 10)) * 10 }));
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isFarming, farmingStartTime]);

  const startFarming = () => {
    const startTime = Date.now();
    setIsFarming(true);
    setIsFinished(false);
    setFarmingStartTime(startTime);
    setTimeLeft(3 * 60 * 60 * 1000);
    setPointsEarned(0);
    setProgress(0);
    localStorage.setItem('farmingData', JSON.stringify({ startTime, finished: false }));
  };

  const claimFarming = async () => {
    setIsLoading(true);
    try {
      const res = await UserService.claimFarming(userData?.id, pointsEarned);
      if (res.data.success) {
        setIsLoading(false);
        dispatch(setUser(res.data.data));
        toast.success(`${pointsEarned} DNB points claimed successfully!`);

        setPointsEarned(0);
        setIsFarming(false);
        setIsFinished(false);
        setFarmingStartTime(null);
        setTimeLeft(0);
        setProgress(0);
        localStorage.removeItem('farmingData');
      }
    } catch (err) {
      setIsLoading(false);
      const resData = err?.response?.data;
      toast.error(resData?.message || 'Something went wrong');
    }
  };

  return (
    <div className="bg-[#232336] text-white p-4 rounded-2xl mx-4 items-center mt-[70px] justify-between">
      {isFarming ? (
        <>
          <div className="flex items-center text-sm font-medium tracking-wide">
            <div className="flex-2 text-center bg-gradient-1 w-[150px] rounded-[20px] p-2 item-center">
              DNB: {pointsEarned}
            </div>
            <div className="ml-auto flex-2">
              {formatTimeLeft(timeLeft)}
            </div>
          </div>
        </>
      ) : (
        <>
          {isFinished ? (
            <>
                <span className="text-sm font-medium tracking-wide mb-2">
                  Points earned: (DNB {pointsEarned})
                </span>
            <Button
              loading={isLoading}
              className="bg-gradient-1 text-white text-[13px] px-4 py-2 items-center w-[120px] h-[35px] hover:bg-gradient-2 flex justify-center mt-2"
              onClick={claimFarming}
            >
              Claim Points
                </Button>
           </>
          ) : (
            <Button
              className="bg-gradient-1 text-white text-[13px] px-4 py-2 items-center w-[100%] h-[35px] hover:bg-gradient-2 flex justify-center"
              onClick={startFarming}
            >
              🧺 Start Farming DNB
            </Button>
          )}
        </>
      )}
      <div className="w-full h-2 bg-gray-700 rounded-full mt-4">
        <div
          className="h-full bg-gradient-1 rounded-full transition-all duration-500"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default FarmingCard;
