import React, { useState } from 'react';
import CourseCard from '../components/CourseCard';
import BottomSheet from '../components/BottomSheet';
import Button from '../components/Button';
import { IoClose, IoSearch } from "react-icons/io5";
import { toast } from 'react-toastify';

const dummyCourses = [
  {
    name: 'Introduction to Blockchain',
    points: 1200,
    image: 'https://res.cloudinary.com/dq3xcffbi/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1725445759/blockchain-colorful-block-vector-19253041_goac3c.jpg',
    type: 'pdf',
    link: 'https://www.google.com',
    description: `Blockchain is a revolutionary technology that underpins cryptocurrencies like Bitcoin, but its applications extend far beyond digital currencies. At its core, a blockchain is a decentralized and distributed digital ledger that records transactions across multiple computers in a way that the recorded transactions cannot be altered retroactively without the alteration of all subsequent blocks and the consensus of the network.`,
  },
  {
    name: 'Introduction to Cryptocurrency',
    points: 950,
    image: 'https://res.cloudinary.com/dq3xcffbi/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1725445783/cypto_tdueig.jpg',
    type: 'video',
    link: 'https://www.google.com',
    description: `A cryptocurrency is a digital or virtual currency that uses cryptography for security. A cryptocurrency is difficult to counterfeit because of this security feature. Many cryptocurrencies are decentralized networks based on blockchain technology—a distributed ledger enforced by a disparate network of computers.`,
  },
  {
    name: 'Introduction to DeFi',
    points: 1300,
    image: 'https://res.cloudinary.com/dq3xcffbi/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1725445759/defi-decentralized-finance-image_1830802_me9bth.jpg',
    type: 'pdf',
    link: 'https://www.buffalo.edu/content/dam/www/ubblockchain/files/basics/001%20What%20is%20Blockchain.pdf',
    description: 'Decentralized finance (DeFi) is a blockchain-based form of finance that does not rely on central financial intermediaries such as brokerages, exchanges, or banks to offer traditional financial instruments, and instead utilizes smart contracts on blockchains, the most common being Ethereum.',
  },
  {
    name: 'Introduction to NFTs',
    points: 1100,
    image: 'https://res.cloudinary.com/dq3xcffbi/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1725445938/can-jpegs-be-nfts_wjf32q.jpg',
    type: 'video',
    link: 'https://www.google.com',
    description: `A non-fungible token (NFT) is a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of digital files.`,
  },
];

const enrolledCourses = [
  // {
  //   name: 'Introduction to DeFi',
  //   points: 1300,
  //   image: 'https://via.placeholder.com/150',
  //   type: 'pdf',
  //   link: 'https://www.buffalo.edu/content/dam/www/ubblockchain/files/basics/001%20What%20is%20Blockchain.pdf',
  //   description: 'Decentralized finance (DeFi) is a blockchain-based form of finance that does not rely on central financial intermediaries such as brokerages, exchanges, or banks to offer traditional financial instruments, and instead utilizes smart contracts on blockchains, the most common being Ethereum.',
  // },
];

const Study = () => {
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isCourseStarted, setIsCourseStarted] = useState(false);
  const [activeTab, setActiveTab] = useState('Courses'); // State to manage active tab
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false); // State to manage search dialog
  const [searchQuery, setSearchQuery] = useState('');

  const coursesToDisplay = activeTab === 'Courses' ? dummyCourses : enrolledCourses;

  return (
    <div className="flex flex-col min-h-screen bg-[#16171A] text-white p-6">
      <div className="flex items-center justify-between absolute top-6 left-6 right-6">
        <div>
          <h1 className="text-3xl font-bold">Courses</h1>
          <p className="mt-1 text-[13px] text-lightGray">
            Learn and Earn DNB points
          </p>
        </div>
        <button onClick={() => setIsSearchDialogOpen(true)} className="text-white">
          <IoSearch size={20} />
        </button>
      </div>

      <div className="flex justify-center mt-20 mb-4">
        <button
          onClick={() => setActiveTab('Courses')}
          className={`px-4 py-2 rounded-t-md ${activeTab === 'Courses' ? 'bg-[#232336] text-white' : 'bg-[#1b1b22] text-gray-400'}`}
        >
          Courses
        </button>
        <button
          onClick={() => setActiveTab('Enrolled')}
          className={`px-4 py-2 rounded-t-md ${activeTab === 'Enrolled' ? 'bg-[#232336] text-white' : 'bg-[#1b1b22] text-gray-400'}`}
        >
          Enrolled Courses
        </button>
      </div>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-1 mb-20">
        {coursesToDisplay
          .filter(course => course.name.toLowerCase().includes(searchQuery.toLowerCase()))
          .map((course, index) => (
            <CourseCard
              key={index}
              course={course}
              isCourseModalOpen={isCourseModalOpen}
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              setIsCourseModalOpen={setIsCourseModalOpen}
            />
          ))}
      </div>

      <BottomSheet
        className="h-[100%]"
        isOpen={isCourseModalOpen}
        onClose={() => setIsCourseModalOpen(false)}
      >
        <div className="relative">
          <h1 className="text-white text-2">{selectedCourse?.name}</h1>
          <button
            className="absolute top-2 right-2 text-white"
            onClick={() => {
              setIsCourseStarted(false);
              setIsCourseModalOpen(false);
              setSelectedCourse(null);
            }}
          >
            <IoClose size={20} />
          </button>
            <div className="flex flex-col justify-center">
            <span className="text-white text-[13px] mt-4">{selectedCourse?.description}</span>
            {isCourseStarted ? (
              <div className="flex flex-col items-center justify-center mt-4">
                {selectedCourse?.type === 'pdf' ? (
                  <iframe
                    title={selectedCourse?.name}
                    src={selectedCourse?.link} className="w-[300px] h-[70vh]"></iframe>
                ) : (
                  <video controls className="w-full h-[70vh]">
                    <source src={selectedCourse?.link} type="video/mp4" />
                  </video>
                )}
              </div>
            ) : (
              <>
                {enrolledCourses.some(course => course.name === selectedCourse?.name) ? (
                  <Button
                    className="bg-gradient-1 w-full mt-4"
                    onClick={() => setIsCourseStarted(true)}
                  >
                    Start Course
                  </Button>
                ) : (
                  <Button
                    className="bg-gradient-1 w-full mt-4"
                        onClick={() => {
                          toast.error('Course enrollment is not available at the moment!');
                      // enrolledCourses.push(selectedCourse);
                      // setIsCourseModalOpen(false);
                      // setSelectedCourse(null);
                    }}
                  >
                    Enroll Now
                  </Button>
                )}
              </>
            )}
            </div>
         
        </div>
      </BottomSheet>

      {isSearchDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#232336] p-6 rounded-md w-[90%] max-w-md">
            <h2 className="text-xl font-bold mb-4">Search Courses</h2>
            <input
              type="text"
              className="w-full p-2 rounded-md bg-[#1b1b22] text-white focus:outline-none"
              placeholder="Enter course name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="flex justify-end mt-4">
              <button
                className="text-white bg-red-500 px-4 py-2 rounded-md mr-2"
                onClick={() => {
                  setIsSearchDialogOpen(false);
                  setSearchQuery('');
                }}
              >
                Cancel
              </button>
              <button
                className="text-white bg-green-500 px-4 py-2 rounded-md"
                onClick={() => setIsSearchDialogOpen(false)}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Study;
