import React from 'react';
import { FaUserPlus } from 'react-icons/fa';
import {toast } from 'react-toastify';

const InviteFriendCard = ({userData}) => {
  return (
    <div className="bg-[#232336] p-4 rounded-[15px] mb-6">
      <div className="flex justify-between items-center mb-3">
        <div>
          <h2 className="text-lg font-bold">Invite a Friend</h2>
          <div className="flex items-center mt-1">
            <button className="bg-gradient-1 text-white px-1 py-1 rounded-[10px] text-[10px] mr-2">+1,000 DNB</button>
            <span className="text-gray-400 text-[12px]">For your friend + 10% of his DNB earning.</span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full mt-3">
        <button
          onClick={() => {
            const referUrl = `https://t.me/dynabitBot?start=${userData?.id}`;
            const message = `Join DynabitBot Explore, Play, Learn and earn DNB points! ${referUrl}`;
        
          navigator.clipboard.writeText(message);
          toast.success('Invite link copied to clipboard!');
          }}
          className="bg-gradient-1 text-white py-2 px-4 w-[85%] rounded-[10px] flex justify-center items-center">
          Copy Invite Link
        </button>
        <FaUserPlus className="bg-gradient-2 p-2 w-10 h-10 rounded-[10px] text-white ml-2" size={24} />
      </div>
    </div>
  );
};

export default InviteFriendCard;
