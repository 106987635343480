import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Game from './pages/Game';     
import Study from './pages/Study';     
import Task from './pages/Task';     
import Friends from './pages/Friends';  
import Leaderboard from './pages/Leaderboard';  
import BottomNavigation from './components/BottomNavigation';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';
const isPhone = window.innerWidth < 600;
const AppContent = () => {
  const location = useLocation();
  const showBottomNavigation = !['/', '/landing'].includes(location.pathname);
  const [isTelegramMiniApp, setIsTelegramMiniApp] = useState(true);

  if (isPhone && isTelegramMiniApp) {
    return (
      <>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <TonConnectUIProvider manifestUrl="https://resources.dynabit.space/tonconnect-manifest.json">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/game" element={<Game />} />          
        <Route path="/tasks" element={<Task />} />          
        <Route path="/study" element={<Study />} />          
        <Route path="/friends" element={<Friends />} />     
        <Route path="/profile" element={<Profile />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
        {showBottomNavigation && <BottomNavigation />}

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          style={{
            top: '80px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '300px',
          }}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="dark"
        />
      </TonConnectUIProvider>
      </PersistGate>
      </Provider>
      </>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-[#16171A] text-white">
        <h1 className="text-3xl font-bold ml-10 mr-10 text-center">Please open dynabitBot app in Telegram</h1>

        <img
          className="mt-4"
          src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://t.me/dynabitBot"
          alt="qr code"
        />

        <button
          className="bg-primary-light text-black px-4 py-2 mt-4 rounded-md"
          onClick={() => window.open('https://t.me/dynabitBot', '_blank')}
        >
          Open in Telegram
        </button>
      </div>
    );
  }
};

const App = () => {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <AppContent />
      </div>
    </Router>
  );
};

export default App;
