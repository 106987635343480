import { useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';

const useLoading = () => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);


  const startLoading = () => {
    if (ref.current) {
      ref.current.continuousStart();
    }
    setLoading(true);
  };

  const completeLoading = () => {
    if (ref.current) {
      ref.current.complete();
    }
    setLoading(false);
  };

  const LoadingComponent = <LoadingBar className='bg-gradient-1' ref={ref} />;

  return { loading, startLoading, completeLoading, LoadingComponent };
};

export default useLoading;
