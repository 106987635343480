import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLoading from '../hooks/useLoading';
import { toast } from 'react-toastify';
import axios from 'axios';
import { UserService } from '../services/UserService';
import { useSelector, useDispatch } from 'react-redux';
import { setUserData, setUser, setProfileUrl } from '../store/userSlice';

const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { startLoading, completeLoading, LoadingComponent } = useLoading();
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    window.Telegram.WebApp.ready();
    window.Telegram.WebApp.expand();
    init();
  }, []);

  const init = () => {
    const search = window.Telegram.WebApp.initData;
    let data = null;

    if (search) {
      const converted = JSON.parse(`{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => {
        return key === "" ? value : decodeURIComponent(value);
      });
      data = JSON.parse(converted.user);
    } else {
      data = {
        id: 18875099517,
        first_name: "Dynabit",
        last_name: "Default",
        username: "dynabit",
        language_code: "en",
        is_premium: true,
        allows_write_to_pm: true,
      };
    }

    if (data) {
      dispatch(setUserData(data));
      fetchUserProfileAndData(data);
    } else {
      toast.error("No user data found");
      window.location.reload();  // Reload the page if no user data is found
    }
  };

  const fetchUserProfileAndData = async (data) => {
    startLoading();
    try {
      await getUserProfile(data);  // Fetch Telegram profile photo
      const userExists = await getUser(data);  // Try to get user from your API
      
      if (!userExists) {
        await handleSignUp(data);  // Sign up if user does not exist
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      window.location.reload();  // Reload the page on error
    } finally {
      completeLoading();
    }
  };

  const getUserProfile = async (data) => {
    try {
      const { data: profilePhotos } = await axios.get(
        `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/getUserProfilePhotos`,
        { params: { user_id: data.id } }
      );

      if (profilePhotos.result && profilePhotos.result.photos.length > 0) {
        const fileId = profilePhotos.result.photos[0][2].file_id;
        const { data: filePathData } = await axios.get(
          `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/getFile`,
          { params: { file_id: fileId } }
        );

        if (filePathData.result) {
          const filePath = filePathData.result.file_path;
          dispatch(setProfileUrl(`https://api.telegram.org/file/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/${filePath}`));
        }
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const getUser = async (data) => {
    try {
      const res = await UserService.get(data?.id);
      const resData = res.data;

      if (resData.success) {
        dispatch(setUser(resData.data));
        navigate('/home');  // Redirect to home if user is successfully fetched
        return true;
      }
      return false;
    } catch (err) {
      console.error("Error fetching user from API:", err);
      return false;
    }
  };

  const handleSignUp = async (data) => {
    const payload = {
      telegramId: data?.id,
      username: data?.username || 'User',
      firstName: data?.first_name,
      lastName: data?.last_name || 'User',
    };

    try {
      const res = await UserService.create(payload);
      const resData = res.data;

      if (resData.success) {
        dispatch(setUser(resData.data));
        navigate('/home');  // Redirect to home after successful signup
      } else {
        toast.error(resData?.message || "Something went wrong");
        window.location.reload();  // Reload if signup fails
      }
    } catch (err) {
      console.error("Error during signup:", err);
      toast.error(err?.response?.data?.message || "Something went wrong");
      window.location.reload();  // Reload on error
    }
  };

  return (
    <div>
      {LoadingComponent}
      <div
        className="min-h-screen flex flex-col justify-between p-6"
        style={{
          backgroundImage: "url('/splash.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#16171A'
        }}
      >
      </div>
    </div>
  );
};

export default LandingPage;
