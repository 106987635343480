import React, {useState, useEffect} from 'react';
import UserCard from '../components/UserCard';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { UserService } from '../services/UserService';
import Confetti from 'react-confetti';
import BottomSheet from '../components/BottomSheet';
import Button from '../components/Button';
import { IoClose } from "react-icons/io5";
import DailyCipher from '../components/Games/DailyCipher';
import useLoading from '../hooks/useLoading';
import StatsCard from '../components/StatsCard';

const Profile = () => {
  const userData = useSelector((state) => state.user.userData);
  const user = useSelector((state) => state.user.user);
  const profileUrl = useSelector((state) => state.user.profileUrl);
  const [isGameModalOpen, setIsGameModalOpen] = React.useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { startLoading, completeLoading, LoadingComponent } = useLoading();
  const [dailyCipher, setDailyCipher] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userStats, setUserStats] = useState({});

  const getUserStats = async () => {
    startLoading();
    try {
      const res = await UserService.getUserStats(userData?.id);
      if (res.data.success) {
        completeLoading();
        setUserStats(res.data.data);
      }
    } catch (err) {
      completeLoading();
      // const resData = err?.response?.data;
      // toast.error(resData?.message || 'Something went wrong');
    }
  };
  const getDailyCipher = async () => {
    setIsLoading(true);
    startLoading();
    try {
      const res = await UserService.getDailyCipher();
      if (res.data.success) {
        setIsLoading(false);
        completeLoading();
        setDailyCipher(res?.data?.data?.code?.toUpperCase());
        setIsGameModalOpen(true);
      }
    } catch (err) {
      setIsLoading(false);
      completeLoading();
      const resData = err?.response?.data;
      toast.error(resData?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    getUserStats();
  }
  , []);


  return (
    <div className="bg-[#16171A] flex-1 p-2">
      {LoadingComponent}
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={200} recycle={false} />}
      <UserCard userData={userData} user={user} profileUrl={profileUrl} />
      
      <div>
      <div className="bg-[#232336] text-white p-4 rounded-2xl mx-4 flex items-center mt-[70px] justify-between">
          <div className="text-sm font-medium tracking-wide mr-2">
            Got a Dynabit daily cipher code? Claim your reward here!
      </div>
      
      <Button
        loading={isLoading}
        disabled={user?.dailyCipherClaimed}
        className="bg-gradient-1 text-white text-[13px] px-4 py-2 items-center w-[80px] h-[35px] hover:bg-gradient-2 flex justify-center"
        onClick={() => {
          getDailyCipher()
        }}
          >
            {user?.dailyCipherClaimed ? 'Done' : 'Claim'}
      </Button>
    </div>
      </div>

      <div className="mb-[100px]">
        <StatsCard stats={userStats} />
      </div>

      <BottomSheet
        className="h-[100%]"
        isOpen={isGameModalOpen}
        onClose={() => setIsGameModalOpen(false)}
      >
        <div className="relative">
        <h1 className="text-white text-2xl">Daily Cipher</h1>
          <button
            className="absolute top-2 right-2 text-white"
            onClick={() => {
              setIsGameModalOpen(false);
            }}
          >
            <IoClose size={20} />
          </button>
              <div className="flex flex-col items-center justify-center">
              <DailyCipher
                  dailyCipher={dailyCipher}
                  isGameModalOpen={isGameModalOpen}
                  setIsGameModalOpen={setIsGameModalOpen}
                  setShowConfetti={setShowConfetti}
                  showConfetti={showConfetti}
                />
                </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default Profile;
