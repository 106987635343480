import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaRegUser, FaGraduationCap, FaDollarSign } from 'react-icons/fa';
import { FiHome } from "react-icons/fi";
import { PiGameControllerBold } from "react-icons/pi";
import { LuTrophy, LuUsers } from "react-icons/lu";


const BottomNavigation = () => {
  const location = useLocation();

  return (
    <nav className="bg-[#16171A] text-white fixed bottom-0 inset-x-0 p-4 flex justify-around items-center">
      <Link
        to="/home"
        className={`flex flex-col items-center ${location.pathname === '/home' ? 'text-primary-light' : 'text-gray-400'}`}
      >
        <FiHome size={24}/>
      </Link>

      <Link
        to="/game"
        className={`flex flex-col items-center ${location.pathname === '/game' ? 'text-primary-light' : 'text-gray-400'}`}
      >
        <PiGameControllerBold size={24}/>
      </Link>

      {/* <Link
        to="/study"
        className={`flex flex-col items-center ${location.pathname === '/study' ? 'text-primary-light' : 'text-gray-400'}`}
      >
        <FaGraduationCap size={25} />
      </Link> */}

           <Link
        to="/tasks"
        className={`flex flex-col items-center justify-end ${location.pathname === '/tasks' ? 'text-primary-light' : 'text-gray-400'}`}
      >
        <div className="bg-primary-light text-white rounded-full p-2">
          <FaDollarSign size={25} />
        </div>
      </Link>
      
      <Link
        to="/friends"
        className={`flex flex-col items-center ${location.pathname === '/friends' ? 'text-primary-light' : 'text-gray-400'}`}
      >
      <LuUsers size={24}/>
      </Link>

      <Link
        to="/leaderboard"
        className={`flex flex-col items-center ${location.pathname === '/leaderboard' ? 'text-primary-light' : 'text-gray-400'}`}
      >
      <LuTrophy size={24}/>
      </Link>

      <Link
        to="/profile"
        className={`flex flex-col items-center ${location.pathname === '/profile' ? 'text-primary-light' : 'text-gray-400'}`}
      >
        <FaRegUser size={20} />
      </Link>
    </nav>
  );
};

export default BottomNavigation;
