import React from 'react';
import { IoGameController, IoList, IoPeople, IoShare, IoClose, IoCheckmarkDone } from "react-icons/io5";

const StatsCard = ({ stats  }) => {
    return (
        <div>
   <h3 className="text-white text-medium m-5">Statistics</h3>
   <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-1 mt-4 ml-4 mr-4">
    <div className="bg-[#232336] text-white p-4 rounded-[12px] flex items-center justify-between mb-2">
        <div className="flex items-center">
        <IoPeople className="text-[20px] text-white" />
        <div className="ml-4">
            <h2 className="text-[15px] font-medium">Referrals</h2>
        </div>
        </div>
        <div className="text-sm text-primary-light font-medium">
        {stats?.referalCount || 0}
        </div>
    </div>
    
    <div className="bg-[#232336] text-white p-4 rounded-[12px] flex items-center justify-between mb-2">
        <div className="flex items-center">
        <IoList className="text-[20px] text-white" />
        <div className="ml-4">
            <h2 className="text-[15px] font-medium">Tasks</h2>
        </div>
        </div>
        <div className="text-sm text-primary-light font-medium">
        {stats?.taskCount || 0}
        </div>
    </div>
    </div>

    <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-1 mt-2 ml-4 mr-4">
    <div className="bg-[#232336] text-white p-4 rounded-[12px] flex items-center justify-between mb-2">
        <div className="flex items-center">
        <IoGameController className="text-[20px] text-white" />
        <div className="ml-4">
            <h2 className="text-[15px] font-medium">Games</h2>
        </div>
        </div>
        <div className="text-sm text-primary-light font-medium">
        {stats?.gameCount || 0}
        </div>
    </div>
    
    <div className="bg-[#232336] text-white p-4 rounded-[12px] flex items-center justify-between mb-2">
        <div className="flex items-center">
        <IoShare className="text-[20px] text-white" />
        <div className="ml-4">
            <h2 className="text-[15px] font-medium">Stories</h2>
        </div>
        </div>
        <div className="text-sm text-primary-light font-medium">
        {stats?.taskCount || 0}
        </div>
    </div>
            </div>
            
    <h3 className="text-white text-medium m-5">Claimable Tasks</h3>
    <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-1 mt-0 ml-4 mr-4">
    <div className="bg-[#232336] text-white p-4 rounded-[12px] flex items-center justify-between mb-2">
        <div className="flex items-center">
        <div className="ml-0">
            <h2 className="text-[15px] font-medium">Daily Reward</h2>
        </div>
        </div>
        <div className="text-sm text-primary-light font-medium">
                        {
                            stats?.isDailyRewardClaimed ? <IoCheckmarkDone className="text-[20px] text-primary" /> : <IoClose  className="text-[20px] text-primary" />
                        }
        </div>
    </div>
    
    <div className="bg-[#232336] text-white p-4 rounded-[12px] flex items-center justify-between mb-2">
        <div className="flex items-center">
        <div className="ml-0">
            <h2 className="text-[15px] font-medium">Post Story</h2>
        </div>
        </div>
        <div className="text-sm text-primary-light font-medium">
        {
                            stats?.isDailyStoryShared ? <IoCheckmarkDone className="text-[20px] text-primary" /> : <IoClose  className="text-[20px] text-primary" />
                        }
        </div>
    </div>
            </div>
            

    <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-1 mt-2 ml-4 mr-4">
    <div className="bg-[#232336] text-white p-4 rounded-[12px] flex items-center justify-between mb-2">
        <div className="flex items-center">
        <div className="ml-0">
            <h2 className="text-[15px] font-medium">Daily Cipher</h2>
        </div>
        </div>
        <div className="text-sm text-primary-light font-medium">
                        {
                            stats?.isDailyCipherClaimed ? <IoCheckmarkDone className="text-[20px] text-primary" /> : <IoClose  className="text-[20px] text-primary" />
                        }
        </div>
    </div>
    
    <div className="bg-[#232336] text-white p-4 rounded-[12px] flex items-center justify-between mb-2">
        <div className="flex items-center">
        <div className="ml-0">
            <h2 className="text-[15px] font-medium">Daily Course</h2>
        </div>
        </div>
        <div className="text-sm text-primary-light font-medium">
        {
                            stats?.isDailyCipherClaimed ? <IoCheckmarkDone className="text-[20px] text-primary" /> : <IoClose  className="text-[20px] text-primary" />
                        }
        </div>
    </div>
    </div>

    </div>
  );
};

export default StatsCard;
