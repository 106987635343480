import axios from 'axios'
import { util } from './util'

function HttpRequests() {
  this.post = async (url, data) => {
    return await axios.post(url, data, util.getAuthorizedHeaders())
  }
  this.postForm = async (url, data) => {
    return await axios.post(url, data, util.getAuthorizedFileHeaders())
  }
  this.postPublic = async (url, data) => {
    return await axios.post(url, data, util.getHeaders())
  }
  this.put = async (url, data) => {
    return await axios.put(url, data, util.getAuthorizedHeaders())
  }
  this.delete = async (url) => {
    return await axios.delete(url, util.getAuthorizedHeaders())
  }
  this.get = async (url) => {
    console.log(url)
    console.log(util.getAuthorizedHeaders())
    return await axios.get(url, util.getAuthorizedHeaders())
  }
  this.getPublic = async (url, data) => {
    return await axios.get(url, data, util.getHeaders())
  }
}

const http = new HttpRequests()
export { http }
