import React from 'react';

const CourseCard = ({ course, isCourseModalOpen, selectedCourse, setSelectedCourse, setIsCourseModalOpen }) => {
  return (
    <div
      onClick={() => {
        setSelectedCourse(course);
        setIsCourseModalOpen(true);
      }}
      className="bg-[#232336] text-white p-4 rounded-[12px] mb-2 cursor-pointer flex flex-col">
      
      <div className="relative w-full h-[150px] rounded-t-[12px] overflow-hidden">
        <img
          src={course?.image}
          alt={course?.name}
          className="w-full h-full object-cover"
        />

        <div className="absolute top-2 right-2 bg-gradient-1 text-white text-sm font-medium px-2 py-1 rounded-md">
          +{course?.points}
        </div>
      </div>

      <div className="flex items-center justify-between mt-4 px-2">
        <h2 className="text-[15px] font-medium">{course?.name}</h2>
      </div>
    </div>
  );
};

export default CourseCard;
