import React, {useState} from 'react';
import GameCard from '../components/GameCard';
import BottomSheet from '../components/BottomSheet';
import Button from '../components/Button';
import { IoClose } from "react-icons/io5";
import { toast } from 'react-toastify';
import Confetti from 'react-confetti';
import Snake from '../components/Games/Snake';
import Drop from '../components/Games/Drop';

const dummyGames = [
  { logo: '/images/game.png', name: 'Snake', points: 1200 },
  { logo: '/images/game.png', name: 'Drop', points: 950 },
  // { logo: '/images/game.png', name: 'Cardano', points: 1300 },
  // { logo: '/images/game.png', name: 'Puzzle', points: 1100 },
  // { logo: '/images/game.png', name: 'Maze', points: 1000 },
  // { logo: '/images/game.png', name: 'Tetris', points: 1400 },
  // { logo: '/images/game.png', name: 'Pong', points: 900 },
  // { logo: '/images/game.png', name: 'Flappy Bird', points: 800 },
  // { logo: '/images/game.png', name: 'Space Invaders', points: 1500 },
  // { logo: '/images/game.png', name: 'Breakout', points: 1000 },
  // { logo: '/images/game.png', name: 'Asteroids', points: 1200 },
  // { logo: '/images/game.png', name: 'Galaga', points: 1300 },
];

const Game = () => {
  const [isGameModalOpen, setIsGameModalOpen] = React.useState(false);
  const [selectedGame, setSelectedGame] = React.useState(null);
  const [isGameStarted, setIsGameStarted] = React.useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  return (
    <div className="flex flex-col min-h-screen bg-[#16171A] text-white p-6">
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={200} recycle={false} />}
      <div className="absolute top-6 left-6">
      <h1 className="text-3xl font-bold">Games</h1>
        <p className="mt-1 text-[13px] text-lightGray">Play & Earn DNB points</p>
      </div>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-1 mt-20 mb-[100px]">
      {dummyGames.map((game, index) => (
        <GameCard
          key={index}
          logo={game.logo}
          name={game.name}
          points={game.points}
          isGameStarted={isGameStarted}
          isGameModalOpen={isGameModalOpen}
          selectedGame={selectedGame}
          setSelectedGame={setSelectedGame}
          setIsGameModalOpen={setIsGameModalOpen}
          setIsGameStarted={setIsGameStarted}
        />
        ))}
      </div>

    <BottomSheet
        className={`h-[100%] ${isGameStarted ? 'fixed inset-0 z-50' : ''}`}
        isOpen={isGameModalOpen}
        onClose={() => {
          if (!isGameStarted) {
            setIsGameModalOpen(false);
          }
        }}
      >
        <div className="relative">
          <h1 className="text-white text-2xl">{selectedGame} Game</h1>
          <button
            className="absolute top-2 right-2 text-white"
            onClick={() => {
              setIsGameModalOpen(false);
              setIsGameStarted(false);
              toast.error('Game closed successfully!');
            }}
          >
            <IoClose size={20} />
          </button>
          {isGameStarted ? (
            <div className="flex items-center justify-center mt-10">
              {selectedGame === 'Snake' ? (
                <Snake
                  isGameStarted={isGameStarted}
                  setIsGameStarted={setIsGameStarted}
                  showConfetti={showConfetti}
                  setShowConfetti={setShowConfetti}
                  isGameModalOpen={isGameModalOpen}
                  setIsGameModalOpen={setIsGameModalOpen}
                />
              ) : selectedGame === 'Drop' ? (
                <Drop
                  isGameStarted={isGameStarted}
                  setIsGameStarted={setIsGameStarted}
                  showConfetti={showConfetti}
                  setShowConfetti={setShowConfetti}
                  isGameModalOpen={isGameModalOpen}
                  setIsGameModalOpen={setIsGameModalOpen}
                />
              ) : (
                <span className="text-white text-[13px] mt-4">
                  This Game is under development!
                </span>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <img src="/telegram.gif" alt="Daily Reward" className="w-20 h-20 mt-4" />
              <br />
              <Button
                className="bg-gradient-1 text-white text-[13px] py-3 px-3 w-[100%] h-[55px] rounded-[90px] hover:bg-gradient-2 m-4"
                onClick={() => setIsGameStarted(true)}
              >
                Play Now
              </Button>
            </div>
          )}
        </div>
      </BottomSheet>
    </div>
  );
};

export default Game;
