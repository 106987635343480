import React, {useCallback, useState, useEffect, useMemo} from 'react';
import UserCard from '../components/UserCard';
import FarmingCard from '../components/FarmingCard';
import AdCard from '../components/AdCard';
import TaskMajorCard from '../components/TaskMajorCard';
import TaskCard from '../components/TaskCard';
import { IoClose } from "react-icons/io5";
import Confetti from 'react-confetti';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../store/userSlice';
import { useNavigate } from 'react-router-dom';
import { UserService } from '../services/UserService';
import BottomSheet from '../components/BottomSheet';
import Button from '../components/Button';
import useLoading from '../hooks/useLoading';
import { IoVideocam, IoWallet, IoGameController, IoList, IoPersonAdd , IoPeople } from "react-icons/io5";
import { FaRegUser, FaGraduationCap, FaChartLine } from 'react-icons/fa';

const telApp = window.Telegram.WebApp;
const Home = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const user = useSelector((state) => state.user.user);
  const profileUrl = useSelector((state) => state.user.profileUrl);
  const [majorTasks, setMajoTasks] = useState([]);
  const [prevPointCount, setPrevPointCount] = useState(user?.pointCount || 0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isDailyRewardModalOpen, setIsDailyRewardModalOpen] = useState(false);
  const [loadingTaskId, setLoadingTaskId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { startLoading, completeLoading, LoadingComponent } = useLoading();
  const [tasks, setTasks] = useState([]);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isTaskClaimDialogOpen, setIsTaskClaimDialogOpen] = useState(false);
  const [claimCode, setClaimCode] = useState('');
  const [taskStarted, setTaskStarted] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const randomIndex = Math.floor(Math.random() * tasks.length);

  const sampleDailyTasks = [
    { id: 1, name: 'Follow ELO CEO on instagram', point: 100, status: 'finished', action: 'social', link: 'https://t.me/eloofficial' },
    { id: 2, name: 'Follow ELO CEO on facebook', point: 200, status: 'active', action: 'social', link: 'https://t.me/eloofficial' },
    { id: 3, name: 'Task 3', point: 300, status: 'pending', action: 'social', link: 'https://t.me/eloofficial' },
    { id: 4, name: 'Task 4', point: 400, status: 'pending', action: 'video', link: 'https://t.me/eloofficial' },
    { id: 5, name: 'Task 5', point: 500, status: 'pending', action: 'video', link: 'https://t.me/eloofficial' },
    { id: 6, name: 'Task 6', point: 600, status: 'pending', action: 'video', link: 'https://t.me/eloofficial' },
    { id: 7, name: 'Task 7', point: 700, status: 'finished', action: 'academy', link: 'https://t.me/eloofficial' },
    { id: 8, name: 'Task 8', point: 800, status: 'pending', action: 'academy', link: 'https://t.me/eloofficial', code: 'SATOSHI' },
    { id: 9, name: 'Task 9', point: 900, status: 'pending', action: 'academy', link: 'https://t.me/eloofficial', code: 'SATOSHI' },
  { id: 10, name: 'Task 10', point: 1000, status: 'finished', action: 'social', link: 'https://t.me/eloofficial', code: 'SATOSHI' },
];
  const majorTasksList = [
    // { 
    //   id: 13344000, 
    //   name: 'Connect to wallet', 
    //   point: 2000, 
    //   type: 'wallet',
    //   status: user?.walletAddressSet ? 'finished' : 'pending'
    // },
    // { 
    //   id: 13344991, 
    //   name: 'Telegram Premium User', 
    //   point: 1000,
    //   type: 'tgpremium',
    //   status: user?.tgpremium ? 'finished' : 'pending'  
    // },
    { 
      id: 13344001, 
      name: 'Invite 10 friends', 
      point: 1000,
      type: '10referral',
      status: user?.referralCount >= 10 ? 'finished' : 'pending'  
    },
    {
      id: 13344004,
      name: 'Invite 25 friends',
      point: 2000,
      type: '25referral',
      status: user?.referralCount >= 25 ? 'finished' : 'pending'
    },
    { 
      id: 133440008, 
      name: 'Invite 50 friends', 
      point: 5000, 
      type: '50referral',
      status: user?.referralCount >= 50 ? 'finished' : 'pending'
    },
    { 
      id: 133440001, 
      name: 'Take 10 tasks', 
      point: 1000,
      type: '10task',
      status: user?.taskCount >= 10 ? 'finished' : 'pending'
    },
    { 
      id: 133440002, 
      name: 'Play 10 games', 
      point: 1000,
      type: '10game',
      status: user?.gameCount >= 10 ? 'finished' : 'pending' 
    }
  ];

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "development") {
      setTasks(sampleDailyTasks);
    }
    setMajoTasks(majorTasksList);
  }, []);

  const handleTakeMajorTask = useCallback(async (id, name, point, status, type) => {
    setLoadingTaskId(id);
    const payload = {
      id,
      point,
      status,
      name,
      type
    }
    
    try {
      const res = await UserService.takeMajorTask(userData?.id, payload);
      const resData = res?.data;
      if (resData.success) {
        dispatch(setUser(resData.data.user));
        setMajoTasks(prevTasks =>
          prevTasks.map(task => (task.id === id ? { ...task, status: 'finished' } : task))
        );
        toast.success(`Task "${name}" started successfully!`);
      } else {
        toast.error(resData.message);
      }
    } catch (err) {
      const resData = err?.response?.data;
      toast.error(resData?.message || 'Something went wrong');
    } finally {
      setLoadingTaskId(null);
    }
  }, [userData?.id, setUser, majorTasks]);

  const handleClaimReward = async () => {
    setIsLoading(true);
    try {
      const res = await UserService.claimReward(userData?.id);
      if (res.data.success) {
        setIsLoading(false);
        dispatch(setUser(res.data.data.user));
        setShowConfetti(true);
        toast.success('You have claimed your daily reward successfully!');
        getUser();
        setIsDailyRewardModalOpen(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      toast.error(err?.response?.data?.message || 'Something went wrong');
    }
  };

  const getUser = async () => {
    try {
      const res = await UserService.get(userData?.id);
      if (res.data.success) {
        dispatch(
          setUser(res.data.data.user)
        );
        if (!res.data.data.user?.dailyRewardClaimed) setIsDailyRewardModalOpen(true);
      } else {
        // toast.error(res.data.message);
      }
    } catch (err) {
      // toast.error(err?.response?.data?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    getUser();
  }, [userData?.id]);


  const getTasks = useCallback(async () => {
    startLoading()
    try {
      const res = await UserService.getTask(userData?.id);
      const resData = res?.data;
      if (resData.success) {
        completeLoading()
        // limit to only 5
        setTasks(resData?.data?.tasks);
      }
    } catch (err) {
      completeLoading()
      const resData = err?.response?.data;
      // toast.error(resData?.message || 'Something went wrong');
    }
  }, [userData?.id]);

  const handleTakeTask = useCallback(async (id, name, point, status, link) => {
    // setLoadingTaskId(id);
    if (link) {
      if (link.startsWith('https://t.me')) {
        telApp.openTelegramLink(link);
      } else {
        telApp.openLink(link, [{ try_instant_view : true }]);
      }
    }
    setTaskStarted(true);
  }, [userData?.id, setUser, tasks]);

  const handleClaimTask = useCallback(async (id, name, point, status, link) => {
    setLoadingTaskId(id);
    
    try {
      const res = await UserService.takeTask(userData?.id, id);
      const resData = res?.data;
      if (resData.success) {
        dispatch(setUser(resData.data.user));
        setTasks(prevTasks =>
          prevTasks.map(task => (task.id === id ? { ...task, status: 'finished' } : task))
        );
        toast.success(`Task "${name}" claimed successfully!`);
        setShowConfetti(true)
        setSelectedTask(null)
        setIsTaskModalOpen(false)
      } else {
        toast.error(resData.message);
      }
    } catch (err) {
      setLoadingTaskId(null);
      const resData = err?.response?.data;
      toast.error(resData?.message || 'Something went wrong');
    } finally {
      setLoadingTaskId(null);
    }
  }, [userData?.id, setUser, tasks]);

  useEffect(() => {
    if (userData?.id) getTasks();
    if (user?.pointCount !== prevPointCount) {
      setPrevPointCount(user?.pointCount);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    }
  }, [userData, user?.pointCount, prevPointCount]);

  return (
    <div className="bg-[#16171A] flex-1 p-2">
      {LoadingComponent}
      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={200} recycle={false} />}
      <UserCard userData={userData} user={user} profileUrl={profileUrl} />
      <FarmingCard userData={userData} user={user} profileUrl={profileUrl}/>
      {
        tasks && tasks.length > 0 && (
          <div className="overflow-y-auto max-h-[500px] mt-6 mb-2">
          <h3 className="text-white text-medium m-5">Daily Tasks</h3>
            {tasks.map((task, index) => (
              <React.Fragment key={index}>
                <TaskCard
                  task={task}
                  loadingTaskId={loadingTaskId}
                  isTaskModalOpen={isTaskModalOpen}
                  setIsTaskModalOpen={setIsTaskModalOpen}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                />
                {index === randomIndex && (
                  <AdCard userData={userData} user={user} profileUrl={profileUrl} />
                )}
              </React.Fragment>
            ))}
          </div>
        )
      }
      <div className="overflow-y-auto max-h-[300px] mt-2 mb-20">
      {
        tasks?.length === 0 && (
          <div className="flex items-center justify-center h-full">
            <h1 className="text-white text-0xl">No tasks available</h1>
          </div>
        )
        }
      </div>
      
      {/* <div className="overflow-y-auto max-h-[500px] mt-2 mb-20">
        <h3 className="text-white text-medium m-5">Standard Tasks</h3>
        {
          majorTasks?.map((task, index) => (
            <TaskMajorCard key={index} task={task} handleTakeMajorTask={handleTakeMajorTask} loadingTaskId={loadingTaskId} />
          ))
        }
      </div> */}

      <BottomSheet
        className="h-[55%]"
        isOpen={isDailyRewardModalOpen}
        onClose={() => setIsDailyRewardModalOpen(false)}
      >
        <div className="relative">
          <button
            className="absolute top-2 right-2 text-white"
            onClick={() => setIsDailyRewardModalOpen(false)}
          >
            <IoClose size={20} />
          </button>
        <div className="flex flex-col items-center justify-center">
          
          <img src="/telegram.gif" alt="Daily Reward" className="w-20 h-20 mt-4" />
          <h1 className="text-white text-2xl">Claim your daily reward!</h1>
          <p className="text-white text-sm mt-2 mb-2">Congrats! You get 1,000 DNB</p>

            <Button
            loading={isLoading}
            className="bg-gradient-1 text-white text-[13px] py-3 px-3 w-[55%] h-[55px] rounded-[90px] hover:bg-gradient-2 m-4"
            onClick={() => handleClaimReward()}
          >
            Claim Now
          </Button>
        </div>
        </div>
      </BottomSheet>


      <BottomSheet
        className="h-[50%]"
        isOpen={isTaskModalOpen}
        onClose={() => setIsTaskModalOpen(false)}
      >
        <div className="relative">
          <button
            className="absolute top-2 right-2 text-white"
            onClick={() => {
              setIsTaskModalOpen(false);
              setSelectedTask(null);
            }}
          >
            <IoClose size={20} />
          </button>
          <div className="flex flex-col justify-center">
            <div className="flex flex-col items-center justify-center h-full">
            <div className="mb-4">
              {selectedTask?.action === 'wallet' && <IoWallet className="text-[60px] text-white" />}
              {selectedTask?.action === 'video' && <IoVideocam className="text-[60px] text-white" />}
              {selectedTask?.action === 'social' && <IoPeople className="text-[60px] text-white" />}
              {selectedTask?.action === 'academy' && <FaGraduationCap className="text-[60px] text-white" />}
              {selectedTask?.action === '10game' && <IoGameController className="text-[60px] text-white" />}
              {selectedTask?.action === '10task' && <IoList className="text-[60px] text-white" />}
              {selectedTask?.action === '10referral' && <IoPersonAdd className="text-[60px] text-white" />}
              {selectedTask?.action === '25referral' && <IoPersonAdd className="text-[60px] text-white" />}
              {selectedTask?.action === '50referral' && <IoPersonAdd className="text-[60px] text-white" />}
            </div>
            <div className="text-white text-xl mb-0">
              {selectedTask?.name}
            </div>
              <div className="text-white text-[12px] mb-4">
              +{selectedTask?.point} DNB
              </div>
              
              <Button
                  // loading={loadingTaskId === selectedTask?.id}
                  disabled={taskStarted}
                  className="bg-gradient-1 text-white text-[13px] px-4 py-2 items-center w-full h-[35px] rounded-[100px] hover:bg-gradient-2 flex justify-center mt-2"
                  onClick={() => handleTakeTask(selectedTask?.id, selectedTask?.name, selectedTask?.point, selectedTask?.status, selectedTask?.link)}
                >
                  {selectedTask?.status === 'finished' ? 'Done' : 'Start Task'}
              </Button>
              
              {/* another button to check the task */}
              <Button
                  loading={loadingTaskId === selectedTask?.id}
                  color={selectedTask?.status === 'finished' ? 'success' : 'primary'}
                  className="bg-gradient-3 text-white text-[13px] px-4 py-2 items-center w-full h-[35px] rounded-[100px] hover:bg-gradient-2 flex justify-center mt-2"
                  onClick={() => {
                    if (!taskStarted) {
                      toast.error('Task not started yet');
                      return;
                    }
                  
                    if (selectedTask?.action === 'academy') {
                      setIsTaskClaimDialogOpen(true);
                    } else {
                      handleClaimTask(selectedTask?.id, selectedTask?.name, selectedTask?.point, selectedTask?.status, selectedTask?.link)
                    }
                  }}
                  disabled={selectedTask?.status === 'finished'}
                >
                {selectedTask?.status === 'finished' ? 'Check Task' : 'Check Task'}
              </Button>


          </div>
          </div>
         
        </div>
      </BottomSheet>

      {isTaskClaimDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#232336] p-6 rounded-md w-[90%] max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl text-white font-bold">Reward Code</h2>
              <button onClick={() => setIsTaskClaimDialogOpen(false)} className="text-white">
                <IoClose size={24} />
              </button>
            </div>
            <p className="text-white text-[13px] mb-4">Enter the code from the video to claim your reward</p>
            <input
              type="text"
              className="w-full p-2 rounded-md bg-[#1b1b22] text-white focus:outline-none"
              placeholder="Code"
              value={claimCode}
              onChange={(e) => {
                setClaimCode(e.target.value.toUpperCase());
              }}
            />
            <div className="flex justify-end mt-4">
              <Button
                className="bg-gradient-3 text-white text-[13px] px-4 py-2 items-center w-full h-[35px] rounded-[100px] hover:bg-gradient-2 flex justify-center mt-2"
                onClick={() => {
                  if (selectedTask?.code === claimCode) {
                    setIsTaskClaimDialogOpen(false);
                    handleClaimTask(selectedTask?.id, selectedTask?.name, selectedTask?.point, selectedTask?.status, selectedTask?.link)
                  } else {
                    toast.error('Invalid code');
                  }
                }}
              >
                Claim
              </Button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Home;
