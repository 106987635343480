import { config } from './configs'

function Util() {
  this.getHeaders = () => {
    return {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json; charset=utf-8',
        'e-api-token': config.authToken,
      },
    }
  }
  this.getAuthorizedHeaders = () => {
    return {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json; charset=utf-8',
        'e-api-token': config.authToken,
      },
    }
  }

  this.getAuthorizedFileHeaders = () => {
    return {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json; charset=utf-8',
        'e-api-token': config.authToken,
      },
    }
  }
}
const util = new Util()
export { util }
