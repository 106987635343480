import { config } from '../utilities/configs'
import { http } from '../utilities/http'

let UserService = {
  create: async (payload) => {
      const resp = await http.post(config.baseUrl + '/user/signup', payload)
      return resp
  },
  upload: async (request) => {
    console.log(request)
    const resp = await http.post(config.baseUrl + '/user/bulk', request)
    return resp
  },
  update: async (request, id) => {
    const resp = await http.put(config.baseUrl + `/user/${id}`, request)
    return resp
  },
  updatePassword: async (request) => {
    const resp = await http.post(config.baseUrl + `/user/password-reset`, request)
    return resp
  },
  activate: async (id) => {
    const resp = await http.put(config.baseUrl + `/user/status-update/${id}?status=ACTIVE`, {})
    return resp
  },
  deactivate: async (id) => {
    const resp = await http.put(config.baseUrl + `/user/status-update/${id}?status=INACTIVE`, {})
    return resp
  },
  delete: async (id) => {
    const resp = await http.delete(config.baseUrl + `/user/${id}`)
    return resp
  },
  get: async (id) => {
    let url = config.baseUrl + '/user/' + id
    const resp = await http.get(url)
    return resp
  },
  getTask: async (id) => {
    let url = config.baseUrl + '/user/task/' + id
    const resp = await http.get(url)
    return resp
  },
  getTaskByType: async (id, type) => {
    let url = config.baseUrl + '/user/task/' + id + '/' + type
    const resp = await http.get(url)
    return resp
  },
  getDailyCipher: async () => {
    let url = config.baseUrl + '/user/daily/cipher'
    const resp = await http.get(url)
    return resp
  },
  getStories: async () => {
    let url = config.baseUrl + '/user/story/all'
    const resp = await http.get(url)
    return resp
  },
  claimStory: async (userId, points) => {
    let url = config.baseUrl + `/user/claim/story/${userId}/${points}`
    const resp = await http.put(url)
    return resp
  },
  claimFarming: async (userId, points) => {
    let url = config.baseUrl + `/user/claim/farming/${userId}/${points}`
    const resp = await http.put(url)
    return resp
  },
  claimDailyCipher: async (userId) => {
    let url = config.baseUrl + `/user/claim/dailycipher/${userId}`
    const resp = await http.put(url)
    return resp
  },
  getLeaderBorads: async () => {
    let url = config.baseUrl + '/user/leaderboard/top30'
    const resp = await http.get(url)
    return resp
  },
  getReferrals: async (id) => {
    let url = config.baseUrl + '/user/referrals/' + id
    const resp = await http.get(url)
    return resp
  },
  getUserStats: async (id) => {
    let url = config.baseUrl + '/user/stats/' + id
    const resp = await http.get(url)
    return resp
  },
  claimReward: async (userId) => {
    let url = config.baseUrl + '/user/claim/' + userId
    const resp = await http.put(url)
    return resp
  },
  claimGamePoints: async (userId, points) => {
    let url = config.baseUrl + `/user/claim/game/${userId}/${points}`
    const resp = await http.put(url)
    return resp
  },
  takeTask: async (userId, taskId) => {
    let url = config.baseUrl + '/user/task/' + userId + '/' + taskId
    const resp = await http.put(url)
    return resp
  },
  takeMajorTask: async (userId, payload) => {
    let url = config.baseUrl + '/user/task/major/' + userId
    const resp = await http.post(url, payload)
    return resp
  },
  saveWalletAddress: async (payload, userId) => {
    const resp = await http.put(config.baseUrl + '/user/wallet/' +  userId, payload)
    return resp
  },
  getByRole: async (page, role) => {
    let url = config.baseUrl + '/user/list?type=' + role || ''
    if (page) url += `?pag=${page}`

    const resp = await http.get(url)
    return resp
  },
  search: async (page, search, role) => {
    let url = config.baseUrl + `/user/search?page=${page ? page : 0}`

    if (search) url += `&q=${search}`
    if (role) url += `&type=${role}`
    console.log(url)

    const resp = await http.get(url)
    return resp
  },
  getAll: async (page) => {
    let url = config.baseUrl + '/user/list'
    if (page) url += `?page=${page}`

    const resp = await http.get(url)
    return resp
  }
}

export { UserService }
