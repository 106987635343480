import React, { useEffect, useRef } from 'react';

const BottomSheet = ({ isOpen, onClose, children, className }) => {
  const sheetRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sheetRef.current && !sheetRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <div
      ref={sheetRef}
      className={`fixed bottom-0 left-0 right-0 bg-[#232336] p-4 rounded-t-[20px] transform ${isOpen ? 'translate-y-0' : 'translate-y-full'} transition-transform duration-300 ${className}`}
    >
      {children}
    </div>
  );
};

export default BottomSheet;
