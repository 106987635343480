import React, { useState, useEffect, useCallback } from 'react';
import { UserService } from '../../../services/UserService';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../../store/userSlice';
import { toast } from 'react-toastify';
const Index = ({ isGameStarted, setIsGameStarted, isGameModalOpen, setIsGameModalOpen, showConfetti, setShowConfetti }) => {
  const [score, setScore] = useState(0);
  const [time, setTime] = useState(30);
  const [items, setItems] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const claim = async (score) => {
        try {
          const res = await UserService.claimGamePoints(userData?.id, score);
          if (res.data.success) {
            dispatch(setUser(res.data.data));
            setScore(0);
            setTime(30);
            setGameOver(false);
            setItems([]);
            setIsGameModalOpen(false);
            toast.success('Congratulations! You have successfully claimed your reward');
            setIsGameStarted(false);
            setShowConfetti(true);
          }
        } catch (err) {
          const resData = err?.response?.data;
          toast.error(resData?.message || 'Something went wrong');
          setIsGameModalOpen(false);
          setIsGameStarted(false);
        }
  };


  useEffect(() => {
    if (time > 0 && !gameOver) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (time === 0) {
      setGameOver(true);
    }
  }, [time, gameOver]);

  useEffect(() => {
    if (!gameOver) {
      const interval = setInterval(() => {
        const newItem = {
          id: Math.random(),
          type: Math.random() > 0.5 ? 'good' : 'bad', // 50-50 chance for good or bad
          x: Math.floor(Math.random() * 90), // Random horizontal position
          y: 0,
        };
        setItems((prevItems) => [...prevItems, newItem]);

        // Slow down item movement: Move items down every 300ms
        const moveItems = setInterval(() => {
          setItems((prevItems) =>
            prevItems
              .map((item) => ({ ...item, y: item.y + 1 })) // Slower fall increment
              .filter((item) => item.y < 100) // Remove items that go off screen
          );
        }, 300); // Increased interval for slower movement

        return () => clearInterval(moveItems);
      }, 1000); // Items appear less frequently, you can change this to 800ms or so

      return () => clearInterval(interval);
    }


    if (gameOver) {
      toast.error('Game Over!');
      claim(score);
    }


  }, [gameOver]);

  const handleItemClick = (item) => {
    if (item.type === 'good') {
      setScore((prevScore) => prevScore + 1);
    } else {
      setScore(0); // Reset score if 'bad' item is clicked
    }
  };

  return (
    <div className="flex flex-col text-white">
      {!gameOver ? (
        <>
        <div className="relative w-96 h-96 overflow-hidden">
          {items.map((item) => (
            <div
              key={item.id}
              onClick={() => handleItemClick(item)}
              className={`absolute w-8 h-8 rounded-full cursor-pointer ${
                item.type === 'good' ? 'bg-green-500' : 'bg-red-500'
              }`}
              style={{ left: `${item.x}%`, top: `${item.y}%` }}
            ></div>
          ))}
          </div>
              <div className="flex justify-between text-[14px] mb-4 p-[25px]">
                <div className="bg-gradient-1 rounded-[30px] p-2">DNB: {score}</div>
                <div className="ml-auto bg-gradient-2 rounded-[30px] p-2">00:{time}s</div>
              </div>
        </>
      ) : (
          null
        // <div className="text-2xl">Game Over! Your score is: {score}</div>
      )}

      {/* {gameOver && (
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => {
            setScore(0);
            setTime(30);
            setGameOver(false);
            setItems([]);
          }}
        >
          Restart Game
        </button>
      )} */}
    </div>
  );
};

export default Index;
