import React from 'react';
import { IoChatbox } from "react-icons/io5";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const telApp = window.Telegram.WebApp;

const UserCard = ({ userData, user, profileUrl, circlePosition = 'center' }) => {
  const points = user?.pointCount || 0.00;

  const getRank = (points) => {
    if (points >= 1000000000) return { icon: '👑', nextRankPoints: 1000000000 };
    if (points >= 50000000) return { icon: '🌟', nextRankPoints: 1000000000 };
    if (points >= 10000000) return { icon: '🔥', nextRankPoints: 50000000 };
    if (points >= 1000000) return { icon: '💎', nextRankPoints: 10000000 };
    if (points >= 500000) return { icon: '🏆', nextRankPoints: 1000000 };
    if (points >= 100000) return { icon: '🥇', nextRankPoints: 500000 };
    if (points >= 50000) return { icon: '🥈', nextRankPoints: 100000 };
    if (points >= 10000) return { icon: '🥉', nextRankPoints: 50000 };
    if (points > 1000) return { icon: '⭐', nextRankPoints: 10000 };
    return { icon: '🚫', nextRankPoints: 1000 };
  };

  const rankInfo = getRank(points);

  const calculateProgress = (points) => {
    const currentRankPoints = {
      '👑': 1000000000,
      '🌟': 50000000,
      '🔥': 10000000,
      '💎': 1000000,
      '🏆': 500000,
      '🥇': 100000,
      '🥈': 50000,
      '🥉': 10000,
      '⭐': 1000,
      '🚫': 0,
    }[rankInfo.icon];

    const pointsInCurrentRank = points - currentRankPoints;
    const pointsNeededForNextRank = rankInfo.nextRankPoints - currentRankPoints;

    return (pointsInCurrentRank / pointsNeededForNextRank) * 100;
  };

  const percentage = calculateProgress(points);

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '';
  };

  return (
    <div className="bg-gradient-1 text-white p-6 rounded-[30px] w-full max-w-lg mx-auto relative" style={{ height: '160px' }}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {profileUrl ? (
            <img
              src={profileUrl}
              alt="profile"
              className="w-12 h-12 rounded-full"
            />
          ) : (
            <div className="w-12 h-12 rounded-full bg-primary-light flex items-center justify-center text-white">
              {getInitial(userData?.first_name)}
            </div>
          )}
          <div className="ml-4">
            <h2 className="text-[14px] font-medium">{userData?.first_name}</h2>
            <h3 className="text-[20px] font-semibold">
            {typeof user?.pointCount === 'number'
              ? user.pointCount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' DNB'
              : '0.00 DNB'}
          </h3>
          </div>
        </div>
        <IoChatbox size={25} onClick={() => {
            telApp.openTelegramLink("https://t.me/dynabit_community");
          }} />
      </div>

      <svg width="0" height="0">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#6262D9', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#9D62D9', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
      </svg>
      <div className={`flex ${circlePosition === 'left' ? 'justify-start' : circlePosition === 'right' ? 'justify-end' : 'justify-center'} mt-4`}>
        <div className="absolute bottom-[-45px]">
          <div className="w-[110px] h-[110px] rounded-full border-4 border-gradient-to-r from-primary to-secondary flex flex-col items-center justify-center bg-[#16171A]">
            <CircularProgressbar
              value={percentage}
              text={rankInfo.icon}
              styles={buildStyles({
                pathColor: 'url(#gradient)',
                textColor: '#fff',
                textSize: '30px',
                trailColor: '#333',
                backgroundColor: '#16171A',
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
