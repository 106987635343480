import React, { useMemo } from 'react';
import { PiGameControllerBold, PiUser } from "react-icons/pi";

const LeaderBoardCard = ({ user, index }) => {
  const getRank = useMemo(() => {
    const points = user?.pointCount;
    if (points >= 1000000000) return '👑';
    if (points >= 50000000) return '🌟';
    if (points >= 10000000) return '🔥';
    if (points >= 1000000) return '💎';
    if (points >= 500000) return '🏆';
    if (points >= 100000) return '🥇';
    if (points >= 50000) return '🥈';
    if (points >= 10000) return '🥉';
    if (points > 1000) return '⭐';
    return '🚫';
  }, [user?.pointCount]);

  return (
    <div className="bg-[#232336] text-white p-4 rounded-[12px] flex items-center justify-between mb-2">
      <div className="flex items-center">
        <PiUser size={24} />
        <div className="ml-4">
          <h2 className="text-[15px] font-medium">{user?.firstName}</h2>
          <p>
            {typeof user?.pointCount === 'number'
              ? user.pointCount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' DNB'
              : '0.00 DNB'}
          </p>
        </div>
      </div>
      <div className="flex items-center">
        {index < 10 && <span className="mr-2 text-xl">#{index + 1}</span>}
        <span>{getRank}</span>
      </div>
    </div>
  );
};

const LeaderBoard = ({ users }) => {
  if (users.length > 0) {
    return (
      <div>
        <h3 className="text-xl font-semibold text-white mb-4">Top 10 Users</h3>
        {users?.slice(0, 10).map((user, index) => (
          <LeaderBoardCard key={index} user={user} index={index} />
        ))}
        <h3 className="text-xl font-semibold text-white mb-4">Other Users</h3>
        {users?.slice(10).map((user, index) => (
          <LeaderBoardCard key={index + 10} user={user} index={index + 10} />
        ))}
      </div>
    );
  } else {
    return <p className="text-white">No users found</p>;
  }
};

export default LeaderBoard;