import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from './Button';
import { toast } from 'react-toastify';
import { UserService } from '../services/UserService';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/userSlice';

const AdCard = ({ userData, user }) => {
    const dispatch = useDispatch();
    const AdControllerRef = useRef(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isAdAvailable, setIsAdAvailable] = useState(true);

    useEffect(() => {
        AdControllerRef.current = window.Adsgram?.init({ blockId: '2869' });

        const lastAdWatched = localStorage.getItem('lastAdWatched');
        if (lastAdWatched) {
            const currentTime = new Date().getTime();
            const timeDiff = currentTime - lastAdWatched;

            if (timeDiff < 3600000) {
                setIsAdAvailable(false);
            }
        }
    }, []);

    const getUser = useCallback(async () => {
        try {
            const res = await UserService.get(userData?.id);
            const resData = res?.data;
            if (resData.success) {
                dispatch(setUser(res.data.data.user));
            }
        } catch (err) {
            const resData = err?.response?.data;
            console.error(resData);
        }
    }, [dispatch, userData?.id]);

    const onReward = useCallback(() => {
        getUser();
        toast.success('You have earned 1,000 DNB points');
        setIsLoading(false)
        localStorage.setItem('lastAdWatched', new Date().getTime());
        
        setIsAdAvailable(false);
    }, [getUser]);

    const onError = useCallback((result) => {
        toast.error('An error occurred while watching the ad');
    }, []);

    const showAd = useCallback(async () => {
        setIsLoading(true);
        if (AdControllerRef.current) {
            try {
                await AdControllerRef.current.show();
                onReward();
            } catch (result) {
                setIsLoading(false);
                onError(result);
            }
        } else {
            setIsLoading(false);
            onError({
                error: true,
                done: false,
                state: 'load',
                description: 'Adsgram script not loaded',
            });
        }
    }, [onReward, onError]);

    return (
        <div className="bg-[#232336] text-white p-4 rounded-2xl mx-4 flex items-center mt-[10px] justify-between mb-4">
            <div className="text-sm font-medium tracking-wide mr-2">
                Watch and claim +1,000 DNB points
            </div>
            <Button
                loading={isLoading}
                disabled={!isAdAvailable || user?.dailyStoryShared}
                className="bg-gradient-1 text-white text-[13px] px-4 py-2 items-center w-[120px] h-[35px] hover:bg-gradient-2 flex justify-center"
                onClick={showAd}
            >
                {isAdAvailable ? 'Start' : 'In 1 hour'}
            </Button>
        </div>
    );
};

export default AdCard;