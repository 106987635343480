import React, {useCallback, useState, useEffect, useMemo} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UserService } from '../services/UserService'
import InviteFriendCard from '../components/InviteFriendCard';
import useLoading from '../hooks/useLoading';

const Friends = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const [friends, setFriends] = useState([]);
  const { startLoading, completeLoading, LoadingComponent } = useLoading();

  const friendsList = [
    { id: 1, firstName: 'Alice Johnson', avatar: '/images/user.png' },
    { id: 2, firstName: 'Bob Smith', avatar: '/images/user.png' },
    { id: 3, firstName: 'Charlie Williams', avatar: '/images/user.png' },
    { id: 4, firstName: 'Diana Brown', avatar: '/images/user.png' },
    { id: 5, firstName: 'Ethan Davis', avatar: '/images/user.png' },
  ];

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "development") {
      setFriends(friendsList);
    }
    getReferrals();
  }, []);

  const getReferrals = async () => {
    startLoading();
    try {
      const res = await UserService.getReferrals(userData?.id);
      if (res.data.success) {
        completeLoading();
        setFriends(res.data.data.referrals);
      }
    } catch (err) {
      completeLoading();
    }
  };

  return (
    <div className="min-h-screen bg-[#16171A] text-white p-6">
      {LoadingComponent}
      <div className="mb-8">
        <h1 className="text-3xl font-bold">Friends</h1>
        <p className="text-lg text-gray-400 mt-1">
          {friends?.length} {friends?.length === 1 ? 'Friend' : 'Friends'}
        </p>
      </div>

      <InviteFriendCard userData={userData} />
      <div className="grid grid-cols-1 gap-4 mb-[100px]">
        {friends?.map((friend) => (
          <div
            key={friend.id}
            className="flex items-center bg-[#232336] p-3 rounded-[15px]"
          >
             <div className="w-12 h-12 rounded-full bg-primary-light flex items-center justify-center text-white">
                {friend?.firstName?.charAt(0).toUpperCase()}
              </div>
            <span className="ml-4 text-[14px] font-medium">{friend.firstName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Friends;
